export const jobObjOne = {

    title: "Attochron Careers",
    target: "_blank",
    contact: "jimolson@attochron.com",

    jobHeader1: "Photonics Telecommunications Engineer - ",
    jobButton1: "See Job Description",
    jobLink1: require('../../docs/2024 Attochron Photonics Telecoms Engineer Job Description - 090524.pdf'),

    jobHeader2: "Technical Field Engineer - ",
    jobButton2: "See Job Description",
    jobLink2: require('../../docs/2024 Attochron Field Technician Job Description - 090524.pdf'),

    jobHeader3: "IT Systems Administrator - ",
    jobButton3: "See Job Description",
    jobLink3: require('../../docs/2025 Attochron IT Systems Administrator Job Description - 010725.pdf'),

    jobHeader4: "Software Developer - ",
    jobButton4: "See Job Description",
    jobLink4: require('../../docs/2025 Attochron Software Developer Job Description - 010725.pdf'),

}