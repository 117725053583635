import React from 'react';
import { JobContainer, TitleWrapper, Title, JobWrapper, ContactWrapper, ContactLink, ListingWrapper, Header, Button } from './JobElements';

const JobsSection = ({
    title, target, contact,
    jobHeader1, jobLink1, jobButton1,
    jobHeader2, jobLink2, jobButton2,
    jobHeader3, jobLink3, jobButton3,
    jobHeader4, jobLink4, jobButton4,
}) => {
    
    return (
        <>
            <JobContainer>
                <TitleWrapper>
                    <Title>
                        {title}
                    </Title>
                </TitleWrapper>
                <ContactWrapper>
                    <Header>Contact <ContactLink href="mailto:jimolson@attochron.com">{contact}</ContactLink></Header>
                </ContactWrapper>
                <JobWrapper>
                    <ListingWrapper>
                        <Header>{jobHeader1}<Button to={jobLink1} target={target}>{jobButton1}</Button></Header>
                    </ListingWrapper>
                    <ListingWrapper>
                        <Header>{jobHeader2}<Button to={jobLink2} target={target}>{jobButton2}</Button></Header>
                    </ListingWrapper>
                    <ListingWrapper>
                        <Header>{jobHeader3}<Button to={jobLink3} target={target}>{jobButton3}</Button></Header>
                    </ListingWrapper>
                    <ListingWrapper>
                        <Header>{jobHeader4}<Button to={jobLink4} target={target}>{jobButton4}</Button></Header>
                    </ListingWrapper>
                </JobWrapper>
            </JobContainer>
        </>
    )
}

export default JobsSection;