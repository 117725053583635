import React from 'react';
import { SiLinkedin } from 'react-icons/si';
import { StoryHeader, StorySubheader, StoryButton, StoryTextWrapper,
    TeamContainer, TeamTileWrapper, TitleWrapper, Title, TeamR1C2, TeamR1C3, TeamR2C1, TeamR2C2, TeamR2C3, TeamR2C4, TeamR3C1, TeamR3C2, TeamR3C3, TeamR3C4, TeamIMG, TeamName, TeamText, TeamIcon, TeamTileCover, TeamTextWrapper, 
    ConsTileWrapper, ConsR1C1, ConsR1C2, ConsR1C3, ConsR2C1, ConsR2C2, ConsR2C3, ConsName, ConsText, ConsIcon,
    InvestTileWrapper, InvestR1C2, InvestTextWrapper,
    LegalTileWrapper, LegalR1C1, LegalR1C2,
    AdvisorTileWrapper, AdvR1C1, AdvR1C2, AdvR1C3, AdvR2C1, AdvR2C2, AdvR2C3, AdvName, AdvName2, AdvText, AdvIcon,
    PartnerTileWrapper, PartnerR1C1, PartnerR1C2, PartnerR1C3, PartnerR1C4, PartnerR2C1, PartnerR2C2, PartnerR2C3, PartnerR2C4, PartnerIMG, PartnerTileCover,
} from './TeamElements';


const Team = ({ alt, 
        storyHeader, storySubheader,
        teamTitle,
        brianG, brianG_LI, brianG_Name, brianG_Title,
        carterW, carterW_LI, carterW_Name, carterW_Title, 
        garrettO, garrettO_LI, garrettO_Name, garrettO_Title,
        jimO, jimO_LI, jimO_Name, jimO_Title,
        perryS, perryS_LI, perryS_Name, perryS_Title,
        seanC, seanC_LI, seanC_Name, seanC_Title,
        tazC, tazC_LI, tazC_Name, tazC_Title,
        tomC, tomC_LI, tomC_Name, tomC_Title,
        nickV, nickV_LI, nickV_Name, nickV_Title,
        willT, willT_LI, willT_Name, willT_Title,
        consTitle,
        whk, whk_LI, whk_Name, whk_Title,
        patC, patC_LI, patC_Name, patC_Title,
        eclipse, eclipse_LI, eclipse_Name, eclipse_Title,
        davidS, davidS_LI, davidS_Name, davidS_Title,
        jimM, jimM_LI, jimM_Name, jimM_Title,
        sidR, sidR_LI, sidR_Name, sidR_Title,
        investTitle,
        tcr, tcr_LI, tcr_Name, tcr_Title,
        legalTitle,
        ericB, ericB_LI, ericB_Name, ericB_Title,
        alexS, alexS_LI, alexS_Name, alexS_Title,
        advisorTitle,
        robF, robF_LI, robF_Name, robF_Name2, robF_Title,
        johnM, johnM_LI, johnM_Name, johnM_Title,
        tomF, tomF_LI, tomF_Name, tomF_Title,
        tomB, tomB_LI, tomB_Name, tomB_Title,
        janisV, janisV_LI, janisV_Name, janisV_Title,
        jeffS, jeffS_LI, jeffS_Name, jeffS_Name2, jeffS_Title,
        partnerTitle,
        thorlabs, thorlabs_link,
        ott, ott_link,
        lumen, lumen_link,
        nuphoton, nuphoton_link,
        droplet, droplet_link,
        keysight, keysight_link,
        viavi, viavi_link,
        conclusive, conclusive_link,
    }) => {
    return (
        <>
            <TeamContainer>
                <TitleWrapper>
                    <StoryHeader>{storyHeader}</StoryHeader>
                </TitleWrapper>
                <StoryTextWrapper>
                    <StorySubheader>{storySubheader}</StorySubheader>
                    <StoryButton to='/story'>Read More</StoryButton>
                </StoryTextWrapper>
                <TitleWrapper>
                    <Title>{teamTitle}</Title>
                </TitleWrapper>
                <TeamTileWrapper>
                    <TeamR1C2>
                        <TeamIMG src={tomC} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{tomC_Name}</TeamName>
                                <TeamText>{tomC_Title}</TeamText>
                                <TeamIcon  to={tomC_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR1C2>
                    <TeamR1C3>
                        <TeamIMG src={jimO} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{jimO_Name}</TeamName>
                                <TeamText>{jimO_Title}</TeamText>
                                <TeamIcon  to={jimO_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR1C3>
                    <TeamR2C1>
                        <TeamIMG src={brianG} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{brianG_Name}</TeamName>
                                <TeamText>{brianG_Title}</TeamText>
                                <TeamIcon to={brianG_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR2C1>
                    <TeamR2C2>
                        <TeamIMG src={tazC} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{tazC_Name}</TeamName>
                                <TeamText>{tazC_Title}</TeamText>
                                <TeamIcon to={tazC_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR2C2>
                    <TeamR2C3>
                        <TeamIMG src={carterW} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{carterW_Name}</TeamName>
                                <TeamText>{carterW_Title}</TeamText>
                                <TeamIcon to={carterW_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR2C3>
                    <TeamR2C4>
                        <TeamIMG src={seanC} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{seanC_Name}</TeamName>
                                <TeamText>{seanC_Title}</TeamText>
                                <TeamIcon to={seanC_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR2C4>
                    <TeamR3C1>
                        <TeamIMG src={perryS} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{perryS_Name}</TeamName>
                                <TeamText>{perryS_Title}</TeamText>
                                <TeamIcon to={perryS_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR3C1>
                    <TeamR3C2>
                        <TeamIMG src={garrettO} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{garrettO_Name}</TeamName>
                                <TeamText>{garrettO_Title}</TeamText>
                                <TeamIcon to={garrettO_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR3C2>
                    <TeamR3C3>
                        <TeamIMG src={nickV} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{nickV_Name}</TeamName>
                                <TeamText>{nickV_Title}</TeamText>
                                <TeamIcon to={nickV_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR3C3>
                    <TeamR3C4>
                        <TeamIMG src={willT} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{willT_Name}</TeamName>
                                <TeamText>{willT_Title}</TeamText>
                                <TeamIcon to={willT_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </TeamR3C4>
                </TeamTileWrapper>
                <TitleWrapper>
                    <Title>{consTitle}</Title>
                </TitleWrapper>
                <ConsTileWrapper>
                    <ConsR1C1>
                        <TeamIMG src={whk} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <ConsName>{whk_Name}</ConsName>
                                <ConsText>{whk_Title}</ConsText>
                                <ConsIcon to={whk_LI} target='_blank'><SiLinkedin/></ConsIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </ConsR1C1>
                    <ConsR1C2>
                        <TeamIMG src={jimM} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{jimM_Name}</TeamName>
                                <TeamText>{jimM_Title}</TeamText>
                                {/* <TeamIcon to={jimM_LI} target='_blank'><SiLinkedin/></TeamIcon> */}
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </ConsR1C2>
                    <ConsR1C3>
                        <TeamIMG src={sidR} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <ConsName>{sidR_Name}</ConsName>
                                <ConsText>{sidR_Title}</ConsText>
                                <ConsIcon to={sidR_LI} target='_blank'><SiLinkedin/></ConsIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </ConsR1C3>
                    <ConsR2C1>
                        <TeamIMG src={patC} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{patC_Name}</TeamName>
                                <TeamText>{patC_Title}</TeamText>
                                <TeamIcon to={patC_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </ConsR2C1>
                    <ConsR2C2>
                        <TeamIMG src={davidS} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{davidS_Name}</TeamName>
                                <TeamText>{davidS_Title}</TeamText>
                                {/* <TeamIcon to={davidS_LI} target='_blank'><SiLinkedin/></TeamIcon> */}
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </ConsR2C2>
                    <ConsR2C3>
                        <TeamIMG src={eclipse} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{eclipse_Name}</TeamName>
                                <TeamText>{eclipse_Title}</TeamText>
                                <TeamIcon to={eclipse_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </ConsR2C3>
                </ConsTileWrapper>
                <TitleWrapper>
                    <Title>{investTitle}</Title>
                </TitleWrapper>
                <InvestTileWrapper>
                    <InvestR1C2>
                        <TeamIMG src={tcr} alt={alt}/>
                        <TeamTileCover>
                            <InvestTextWrapper>
                                <TeamName>{tcr_Name}</TeamName>
                                <TeamText>{tcr_Title}</TeamText>
                                <TeamIcon to={tcr_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </InvestTextWrapper>
                        </TeamTileCover>
                    </InvestR1C2>
                </InvestTileWrapper>
                <TitleWrapper>
                    <Title>{advisorTitle}</Title>
                </TitleWrapper>
                <AdvisorTileWrapper>
                    <AdvR1C1>
                        <TeamIMG src={robF} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <AdvName>{robF_Name}</AdvName>
                                <AdvName2>{robF_Name2}</AdvName2>
                                <TeamText>{robF_Title}</TeamText>
                                <AdvIcon to={robF_LI} target='_blank'><SiLinkedin/></AdvIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </AdvR1C1>
                    <AdvR1C2>
                        <TeamIMG src={jeffS} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <AdvName>{jeffS_Name}</AdvName>
                                <AdvName2>{jeffS_Name2}</AdvName2>
                                <AdvText>{jeffS_Title}</AdvText>
                                <AdvIcon to={jeffS_LI} target='_blank'><SiLinkedin/></AdvIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </AdvR1C2>
                    <AdvR1C3>
                        <TeamIMG src={tomB} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <AdvName>{tomB_Name}</AdvName>
                                <TeamText>{tomB_Title}</TeamText>
                                <TeamIcon to={tomB_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </AdvR1C3>
                    <AdvR2C1>
                        <TeamIMG src={tomF} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <AdvName>{tomF_Name}</AdvName>
                                <TeamText>{tomF_Title}</TeamText>
                                <TeamIcon to={tomF_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </AdvR2C1>
                    <AdvR2C2>
                        <TeamIMG src={janisV} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <AdvName>{janisV_Name}</AdvName>
                                <TeamText>{janisV_Title}</TeamText>
                                <TeamIcon to={janisV_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </AdvR2C2>
                    <AdvR2C3>
                        <TeamIMG src={johnM} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <AdvName>{johnM_Name}</AdvName>
                                <AdvText>{johnM_Title}</AdvText>
                                <TeamIcon to={johnM_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </AdvR2C3>
                </AdvisorTileWrapper>
                <TitleWrapper>
                    <Title>{legalTitle}</Title>
                </TitleWrapper>
                <LegalTileWrapper>
                    <LegalR1C1>
                        <TeamIMG src={ericB} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{ericB_Name}</TeamName>
                                <TeamText>{ericB_Title}</TeamText>
                                <TeamIcon to={ericB_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </LegalR1C1>
                    <LegalR1C2>
                        <TeamIMG src={alexS} alt={alt}/>
                        <TeamTileCover>
                            <TeamTextWrapper>
                                <TeamName>{alexS_Name}</TeamName>
                                <TeamText>{alexS_Title}</TeamText>
                                <TeamIcon to={alexS_LI} target='_blank'><SiLinkedin/></TeamIcon>
                            </TeamTextWrapper>
                        </TeamTileCover>
                    </LegalR1C2>
                </LegalTileWrapper>
                <TitleWrapper>
                    <Title>{partnerTitle}</Title>
                </TitleWrapper>
                <PartnerTileWrapper>
                    <PartnerR1C1>
                        <PartnerIMG src={thorlabs} alt={alt}/>
                        <PartnerTileCover to={thorlabs_link} target='_blank'>
                        </PartnerTileCover>
                    </PartnerR1C1>
                    <PartnerR1C2>
                        <PartnerIMG src={ott} alt={alt}/>
                        <PartnerTileCover to={ott_link} target='_blank'>
                        </PartnerTileCover>
                    </PartnerR1C2>
                    <PartnerR1C3>
                        <PartnerIMG src={lumen} alt={alt}/>
                        <PartnerTileCover to={lumen_link} target='_blank'>
                        </PartnerTileCover>
                    </PartnerR1C3>
                    <PartnerR1C4>
                        <PartnerIMG src={nuphoton} alt={alt}/>
                        <PartnerTileCover to={nuphoton_link} target='_blank'>
                        </PartnerTileCover>
                    </PartnerR1C4>
                    <PartnerR2C1>
                        <PartnerIMG src={droplet} alt={alt}/>
                        <PartnerTileCover to={droplet_link} target='_blank'>
                        </PartnerTileCover>
                    </PartnerR2C1>
                    <PartnerR2C2>
                        <PartnerIMG src={keysight} alt={alt}/>
                        <PartnerTileCover to={keysight_link} target='_blank'>
                        </PartnerTileCover>
                    </PartnerR2C2>
                    <PartnerR2C3>
                        <PartnerIMG src={viavi} alt={alt}/>
                        <PartnerTileCover to={viavi_link} target='_blank'>
                        </PartnerTileCover>
                    </PartnerR2C3>
                    <PartnerR2C4>
                        <PartnerIMG src={conclusive} alt={alt}/>
                        <PartnerTileCover to={conclusive_link} target='_blank'>
                        </PartnerTileCover>
                    </PartnerR2C4>
                </PartnerTileWrapper>
            </TeamContainer>
        </>
    )
}

export default Team